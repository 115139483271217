import React from 'react';

import { Button } from 'src/components/atoms/Button';
import { GitHubButton } from 'src/components/atoms/GitHubButton';
import { InlineList } from 'src/components/molecules/InlineList';
import { Hero } from 'src/components/organisms/Hero';
import { Link } from 'src/containers/Link';
import { FluidImg } from 'src/embeds/image/image-dato';
import HeaderColorState from 'src/layouts/HeaderColorState';

/*
  image
  ctas
  customData
    flags
      alternative: boolean
      primary?: boolean;
      wavey?: boolean; (exclusive)
      sloped?: boolean; (exclusive)
      width?: 'large' | 'xlarge';
      subtitle?: ReactNode;
      subtitleWidth?: 'small' | 'medium' | 'large' | 'xlarge' | 'full';
 */

const ctaComponents = {
  Button: Button,
  GitHubButton({ to, a11yText, children }) {
    return (
      <GitHubButton
        href={to}
        data-icon="octicon-star"
        data-show-count="true"
        data-size="large"
        aria-label={a11yText}
      >
        {children}
      </GitHubButton>
    );
  },
  Link: Link,
};

export default function LegacyHeader({
  heading,
  preHeading,
  content,
  contentNode,
  ctas,
  backgroundImage,
  customData,
}) {
  customData = JSON.parse(customData || '{}');

  return (
    <>
      <HeaderColorState
        {...{
          alternative: false,
          primary: true,
          dark: false,
          ...customData?.flags
        }}
      />
      <Hero
        title={heading}
        subtitle={<div dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }} />}
        category={preHeading}
        actions={
          ctas?.length > 0 ? (
            <InlineList centered noMargin>
              {ctas.map((cta, i) => {
                const Action = ctaComponents[cta.component || 'Button'];
                const customData = JSON.parse(cta.customData);
                return (
                  <li key={i}>
                    <Action to={cta.url} aria-label={cta.a11yText || cta.text} {...customData}>
                      {cta.text}
                    </Action>
                  </li>
                );
              })}
            </InlineList>
          ) : null
        }
        backgroundImage={
          !!backgroundImage && (
            <FluidImg
              pictureStyle={{ objectFit: 'contain' }}
              maxWidth={null}
              data={backgroundImage}
              style={backgroundImage.customData || {}}
            />
          )
        }
        centered
        {...(customData.flags || {})}
      />
    </>
  );
}
